import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import React, { useState } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DeleteIcon from '@material-ui/icons/Delete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import * as AdminPanelActions from '../AdminPortal/store/actions';
import SuccessPointsDialog from '../AdminPortal/SuccessPoints/successPointsDialog';

const rows = [
  {
    id: 'agent_name',
    align: 'left',
    disablePadding: false,
    label: 'Agent Name',
    sort: true,
  },
  {
    id: 'months_in_business',
    align: 'left',
    disablePadding: false,
    label: 'Month in Business',
    sort: true,
  },
  {
    id: 'field',
    align: 'left',
    disablePadding: false,
    label: 'Success Points',
    sort: false,
  },
];

const useStyles = makeStyles(theme => ({
  actionsButtonWrapper: {
    background: theme.palette.background.paper,
  },
}));

function AgentSummaryTable(props) {
  const classes = useStyles(props);
  const dispatch = useDispatch();

  const [selectedClassMenu, setSelectedClassMenu] = useState(null);

  const createSortHandler = property => event => {
    props.onRequestSort(event, property);
  };

  function openSelectedClassMenu(event) {
    setSelectedClassMenu(event.currentTarget);
  }

  function closeSelectedClassMenu() {
    setSelectedClassMenu(null);
  }

  // PopOver
  const [popoverAnchorEl, setAnchorEl] = React.useState(null);
  const [openPopoverRow, setOpenPopoverRow] = React.useState(null);

  const showPopOverModal = (event, row) => {
    setAnchorEl(event.currentTarget);
    setOpenPopoverRow(row);
  };

  const handlePopOverClose = () => {
    setOpenPopoverRow(null);
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(popoverAnchorEl);

  // Delete Confirmation
  const [openDeleteConfirmation, setDeleteConfirmation] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const showDeleteConfirmation = () => {
    setDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmation(false);
    handlePopOverClose();
  };

  const deleteSuccessPoint = e => {
    e.stopPropagation();
    const deleteId = openPopoverRow.id.split('sp-')[1];
    // Delete Success Point
    dispatch(AdminPanelActions.deleteSuccessPoint(deleteId));
    // Close modals on deletion
    closeDeleteConfirmation();
    handlePopOverClose();
  };

  // Add new Success Point
  const newSuccessPointRecord = () => {
    handlePopOverClose();
    dispatch(AdminPanelActions.openSuccessPointDialog());
  };

  // Add new Success Point
  const editSuccessPointRecord = data => {
    handlePopOverClose();
    dispatch(AdminPanelActions.openEditSuccessPointDialog(data));
  };

  return (
    <>
      <SuccessPointsDialog></SuccessPointsDialog>
      <Dialog
        fullScreen={fullScreen}
        open={openDeleteConfirmation}
        onClose={closeDeleteConfirmation}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete success point (
            {openPopoverRow && openPopoverRow.label})?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={closeDeleteConfirmation} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteSuccessPoint} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <TableHead>
        <TableRow className="h-64">
          {/* <TableCell padding="none" className="relative w-64 text-center">
            <Checkbox
              indeterminate={
                props.numSelected > 0 && props.numSelected < props.rowCount
              }
              checked={props.numSelected === props.rowCount}
              onChange={props.onSelectAllClick}
            />
            {props.numSelected > 0 && (
              <div
                className={clsx(
                  'flex items-center justify-center absolute w-64 top-0 ltr:left-0 rtl:right-0 mx-56 h-64 z-10',
                  classes.actionsButtonWrapper
                )}
              >
                <IconButton
                  aria-owns={selectedClassMenu ? 'selectedClassMenu' : null}
                  aria-haspopup="true"
                  onClick={openSelectedClassMenu}
                >
                  <MoreHorizIcon />
                </IconButton>
                <Menu
                  id="selectedClassMenu"
                  anchorEl={selectedClassMenu}
                  open={Boolean(selectedClassMenu)}
                  onClose={closeSelectedClassMenu}
                >
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        closeSelectedClassMenu();
                      }}
                    >
                      <ListItemIcon className="min-w-40">
                        <DeleteIcon />
                      </ListItemIcon>
                      <ListItemText primary="Remove" />
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            )}
          </TableCell> */}
          {rows.concat(props.partialHeader).map(row => {
            return (
              <TableCell
                style={{ fontWeight: 'bold' }}
                key={row.id}
                align={row.align}
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={
                  props.order.id === row.id ? props.order.direction : false
                }
              >
                {row.sort ? (
                  <>
                    {row.editModal ? (
                      <>
                        <FontAwesomeIcon
                          className="sp-edit-icon"
                          cursor={'pointer'}
                          icon={faPencilAlt}
                          onClick={e => showPopOverModal(e, row)}
                        />
                        <Popover
                          open={isPopoverOpen}
                          anchorEl={popoverAnchorEl}
                          onClose={handlePopOverClose}
                        >
                          <MenuList>
                            <MenuItem>
                              {openPopoverRow && openPopoverRow.label}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                newSuccessPointRecord();
                              }}
                            >
                              <ListItemIcon className="min-w-40">
                                Add
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                editSuccessPointRecord(
                                  openPopoverRow.successPoint
                                );
                              }}
                            >
                              <ListItemIcon className="min-w-40">
                                Edit
                              </ListItemIcon>
                            </MenuItem>
                            <MenuItem
                              onClick={e => {
                                showDeleteConfirmation(e);
                              }}
                            >
                              <ListItemIcon className="min-w-40">
                                Remove
                              </ListItemIcon>
                            </MenuItem>
                          </MenuList>
                        </Popover>
                      </>
                    ) : null}
                    <Tooltip
                      title="Sort"
                      placement={
                        row.align === 'right' ? 'bottom-end' : 'bottom-start'
                      }
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={props.order.id === row.id}
                        direction={props.order.direction}
                        onClick={createSortHandler(row.id)}
                      >
                        {row.label}
                      </TableSortLabel>
                    </Tooltip>
                  </>
                ) : (
                  row.label
                )}
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    </>
  );
}

export default AgentSummaryTable;

import axios from '../../../../configs/axiosConfig';
import { history } from '../../../../App';

export const ALL_USERS = '[ADMIN PORTAL] ALL USERS';
export const CREATE_USER = '[ADMIN PORTAL] CREATE USER';
export const UPDATE_USER = '[ADMIN PORTAL] UPDATE USER';
export const DELETE_USER = '[ADMIN PORTAL] DELETE USER';
export const OPEN_NEW_USERS_DIALOG = '[ADMIN PORTAL] OPEN NEW USERS DIALOG';
export const CLOSE_NEW_USERS_DIALOG = '[ADMIN PORTAL] CLOSE NEW USERS DIALOG';
export const OPEN_EDIT_USERS_DIALOG = '[ADMIN PORTAL] OPEN EDIT USERS DIALOG';
export const CLOSE_EDIT_USERS_DIALOG = '[ADMIN PORTAL] CLOSE EDIT USERS DIALOG';
export const SET_USERS_SEARCH_TEXT = '[ADMIN PORTAL] SET USERS SEARCH TEXT';

axios.defaults.withCredentials = true;

export function getUsersList() {
  const request = axios.get('/all_users/all');

  return (dispatch) =>
    request
      .then((response) => {
        dispatch({
          type: ALL_USERS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        history.push('/login');
      });
}

export function createUser(data) {
  const request = axios.post('/auth/register', data);

  return (dispatch) =>
    request.then(() => {
      Promise.all([
        dispatch({
          type: CREATE_USER,
        }),
      ]).then(() => dispatch(getUsersList()));
    });
}

export function updateUser(userId, data) {
  const request = axios.patch('/users/' + userId, data);

  return (dispatch) =>
    request.then((response) => {
      Promise.all([
        dispatch({
          type: UPDATE_USER,
        }),
      ]).then(() => dispatch(getUsersList()));
    });
}

export function deleteUser(id) {
  const request = axios.delete('/sp/delete/' + id);

  return (dispatch) =>
    request.then((response) => {
      Promise.all([
        dispatch({
          type: DELETE_USER,
        }),
      ]).then(() => dispatch(getUsersList()));
    });
}

export function openUsersDialog() {
  return (dispatch) => {
    dispatch({
      type: OPEN_NEW_USERS_DIALOG,
    });
  };
}

export function closeUsersDialog() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_NEW_USERS_DIALOG,
    });
  };
}

export function openEditUsersDialog(data) {
  return (dispatch) => {
    dispatch({
      type: OPEN_EDIT_USERS_DIALOG,
      data,
    });
  };
}

export function closeEditUsersDialog() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_EDIT_USERS_DIALOG,
    });
  };
}

export function setUsersSearchText(event) {
  return {
    type: SET_USERS_SEARCH_TEXT,
    searchText: event.target.value,
  };
}

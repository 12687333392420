import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../store/actions';
import * as Action from '../../SnackbarMessage/store/actions';
import './successPoints.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { history } from '../../../App';

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const SuccessPointsDialog = () => {
  const defaultForm = {
    sp_name: '',
    amount: '',
    years: '',
    success_type: '',
    metric: '',
    consecutive_years: '',
    weight: '',
    is_default: false,
  };

  const dispatch = useDispatch();
  const [form, setForm] = useState(defaultForm);
  const successPointDialog = useSelector(
    ({ adminPortal }) => adminPortal.agentPortal.successPointDialog
  );

  const successTypes = [
    {
      value: 'Consecutively',
      label: 'Consecutively',
    },
    {
      value: 'At any point in time',
      label: 'At any point in time',
    },
    {
      value: 'On average',
      label: 'On average',
    },
  ];

  const successGreaterLess = [
    {
      value: 'true',
      label: 'Greater than',
    },
    {
      value: 'false',
      label: 'Less than',
    },
  ];

  const basedOnArray = [
    {
      value: 'Lives',
      label: 'Lives',
    },
    {
      value: 'Premiums',
      label: 'Premiums',
    },
    {
      value: 'FYC',
      label: 'FYC',
    },
  ];

  useEffect(() => {
    if (successPointDialog.data) {
      setForm(successPointDialog.data);
    }
    if (successPointDialog.type === 'new') {
      setForm(defaultForm);
    }
  }, [successPointDialog]);

  const handleChange = (event) => {
    if (event.target.name === 'is_default') {
      setForm({ ...form, [event.target.name]: event.target.checked });
    } else {
      let value = event.target.value;
      if (event.target.name === 'amount') {
        value = `${value}`.replace(/k|K/, '000').replace(/m|M/, '000000');
      }
      setForm({ ...form, [event.target.name]: value });
    }
  };

  const closeSuccessPointDialog = () => {
    return successPointDialog.type === 'new'
      ? dispatch(Actions.closeSuccessPointDialog())
      : dispatch(Actions.closeEditSuccessPointDialog());
  };




  const canBeSubmitted = () => {
    return form.sp_name && form.metric && form.amount_greater !== null && form.amount > 0 && form.years > 0 && form.success_type && form.weight > 0 && (form.success_type === 'Consecutively' ? form.consecutive_years > 0 : true);
  };

  const handleSubmit = () => {
    for (const key in form) {
      if (form[key] === ""){
        form[key] = null;
      }
    }
    if (successPointDialog.type === 'new') {
      dispatch(Actions.createSuccessPoint(form))
      .catch((error) => {
        if (error?.response?.status === 401) {
          history.push('/login');
        } else {
          console.log(error);
          dispatch(
            Action.showMessage({
              message: (error && error.message) || 'Something went wrong',
              variant: 'error',
            })
          );
        }
      });
      dispatch(Actions.closeSuccessPointDialog());
    } else {
      const data = Object.assign({}, form);
      data.sp_id = successPointDialog.data.sp_id;
      dispatch(Actions.updateSuccessPoint(data))
      .catch((error) => {
        if (error?.response?.status === 401) {
          history.push('/login');
        } else {
          console.log(error);
          dispatch(
            Action.showMessage({
              message: (error && error.message) || 'Something went wrong',
              variant: 'error',
            })
          );
        }
      });
      dispatch(Actions.closeEditSuccessPointDialog());
    }
  };

  return (
    <Dialog
      open={successPointDialog.open}
      onClose={closeSuccessPointDialog}
      fullWidth
      maxWidth="sm"
    >
      <AppBar style={{ background: '#64bb46' }} position="static" elevation={1}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            {successPointDialog.type === 'new'
              ? 'New Success Point'
              : 'Edit Success Point'}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent classes={{ root: 'p-0' }}>
        <div className="px-16 sm:px-24">
          <FormControl
            style={{ marginBottom: '16px', marginTop: '16px' }}
            required
            fullWidth
          >
            <TextField
              label="Name"
              autoFocus
              name="sp_name"
              value={form.sp_name}
              onChange={handleChange}
              required
              variant="outlined"
            />
          </FormControl>
        </div>
        <div className="px-16 sm:px-24">
          <FormControl
            style={{ marginBottom: '16px' }}
            variant="outlined"
            required
            fullWidth
          >
            <InputLabel id="based-on-outlined-label">Based on</InputLabel>
            <Select
              labelId="based-on-outlined-label"
              name="metric"
              value={form.metric}
              onChange={handleChange}
              label="Based on"
            >
              {basedOnArray.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="px-16 sm:px-24">
          <FormControl
            style={{ marginBottom: '16px' }}
            variant="outlined"
            required
            fullWidth
          >
            <InputLabel id="success-type-outlined-label">
              Success Greater/Less
            </InputLabel>
            <Select
              labelId="success-type-outlined-label"
              name="amount_greater"
              value={form.amount_greater}
              onChange={handleChange}
              label="Success Greater/Less"
            >
              {successGreaterLess.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="px-16 sm:px-24">
          <FormControl
            style={{ marginBottom: '16px', marginTop: '16px' }}
            required
            fullWidth
          >
            <TextField
              label="Amount"
              name="amount"
              value={form.amount}
              onChange={handleChange}
              required
              variant="outlined"
            />
          </FormControl>
        </div>
        <div className="px-16 sm:px-24">
          <FormControl
            style={{ marginBottom: '16px', marginTop: '16px' }}
            required
            fullWidth
          >
            <TextField
              type="number"
              label="Expecting Success by"
              name="years"
              value={form.years}
              onChange={handleChange}
              required
              variant="outlined"
            />
          </FormControl>
        </div>
        <div className="px-16 sm:px-24">
          <FormControl
            style={{ marginBottom: '16px' }}
            variant="outlined"
            required
            fullWidth
          >
            <InputLabel id="success-type-outlined-label">Define by</InputLabel>
            <Select
              labelId="success-type-outlined-label"
              name="success_type"
              value={form.success_type}
              onChange={handleChange}
              label="Define by"
            >
              {successTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {form.success_type=== 'Consecutively' && (
        <div className="px-16 sm:px-24">
          <FormControl
            style={{ marginBottom: '16px', marginTop: '16px' }}
            required
            fullWidth
          >
            <TextField
              type="number"
              label="Consecutive Years"
              name="consecutive_years"
              value={form.consecutive_years}
              onChange={handleChange}
              required
              variant="outlined"
            />
          </FormControl>
        </div>
        )}
        <div className="px-16 sm:px-24">
          <FormControl
            style={{ marginBottom: '16px', marginTop: '16px' }}
            required
            fullWidth
          >
            <TextField
              label="Weight"
              name="weight"
              value={form.weight}
              onChange={handleChange}
              required
              variant="outlined"
            />
          </FormControl>
        </div>

        <div className="px-16 sm:px-24">
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={form.is_default}
                onChange={handleChange}
                name="is_default"
              />
            }
            label="Show in Agent Summary"
          />
        </div>
      </DialogContent>
      <DialogActions className="justify-between p-8">
        <div className="px-16">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit();
            }}
            disabled={!canBeSubmitted()}
          >
            {successPointDialog.type === 'new' ? 'Add' : 'Update'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessPointsDialog;

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './sidebar.css';
import dashboardIcon from '../../assets/dashboard.png';
import successIcon from '../../assets/Success.png';
import settingIcon from '../../assets/settings.png';

function SideBar(props) {
    const [menu, setMenu] = useState(1);
    let history = useHistory();

    useEffect(() => {
        if (props.active === 'adminPortal') setMenu(2)
    }, [props.active])

    const menuChangeHandler = (menuId) => {
        setMenu(menuId)
        // if (menuId === 0) setMenu(0);
        if (menuId === 1) history.push("/agent-success-predictions");
        if (menuId === 2) history.push("/admin-portal/users");
    }

    return (
        <>
            <div className="sidenav">
                {/* <div
                    className={menu === 0 ? 'menu active' : 'menu'}
                    onClick={() => { menuChangeHandler(0) }}
                >
                    <img src={dashboardIcon} alt="dashboardIcon" className="icon" />
                    Agent Dashboard
                </div> */}
                <div
                    className={menu === 1 ? 'menu active' : 'menu'}
                    onClick={() => { menuChangeHandler(1) }}
                >
                    {/* <FontAwesomeIcon className="icon" icon={faFlag} /> */}
                    <img src={successIcon} alt="successIcon" className="icon" />
                    Agent success Predictions
                </div>
                <div
                    className={menu === 2 ? 'menu active' : 'menu'}
                    onClick={() => { menuChangeHandler(2) }}
                >
                    {/* <FontAwesomeIcon className="icon" icon={faCog} /> */}
                    <img src={settingIcon} alt="settingIcon" className="icon" />
                    <span style={{ marginBottom: '5em' }}>Admin Portal</span>
                </div>
            </div>
        </>
    )
}

export default SideBar
import React from 'react';
// import 'bootstrap/dist/css/bootstrap.css';
import './progressBar.css';

const ProgressBar = (props) => {
    const classes = props.progress >= 60 ? 'progress-bar bg-success' : props.progress >= 40 && props.progress < 60 ? 'progress-bar bg-warning' : props.progress >= 20 && props.progress < 40 ? 'progress-bar bg-light-warning' : 'progress-bar bg-danger';
    const width = props.width ? props.width : 'auto';
    return (
        <>
            <div className="progress bar" style={{ 'width': width }}>
                <div className={classes} role="progressbar" style={{ 'width': props.progress + '%' }} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </>
    )
}

export default ProgressBar
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../store/actions';
import * as Action from '../../SnackbarMessage/store/actions';
import './indicators.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { history } from '../../../App';

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const IndicatorDialog = () => {
  const defaultForm = {
    indicator_name: '',
    indicator_type: '',
    month_number: '',
    field: '',
    weight: '',
    is_default: false,
  };

  const dispatch = useDispatch();
  const [form, setForm] = useState(defaultForm);
  const indicatorDialog = useSelector(
    ({ adminPortal }) => adminPortal.agentPortal.indicatorDialog
  );

  const indicatorTypes = [
    {
      value: 'Applications Received',
      label: 'Applications Received',
    },
    {
      value: 'Applications Submitted',
      label: 'Applications Submitted',
    },
    {
      value: 'Applications Placed',
      label: 'Applications Placed',
    },
    {
      value: 'FYC',
      label: 'FYC',
    },
    {
      value: 'Lives',
      label: 'Lives',
    },
  ];

  const defineBy = [
    {
      value: 'history_sum',
      label: 'History Sum',
    },
    {
      value: 'rate_of_change',
      label: 'Rate of change',
    },
    {
      value: 'history_avg',
      label: 'Average',
    },
  ];

  useEffect(() => {
    if (indicatorDialog.data) {
      setForm(indicatorDialog.data);
    }
    if (indicatorDialog.type === 'new') {
      setForm(defaultForm);
    }
  }, [indicatorDialog]);

  const handleChange = event => {
    if (event.target.name == 'is_default') {
      setForm({ ...form, [event.target.name]: event.target.checked });
    } else {
      setForm({ ...form, [event.target.name]: event.target.value });
    }
  };

  const closeIndicatorDialog = () => {
    return indicatorDialog.type === 'new'
      ? dispatch(Actions.closeIndicatorDialog())
      : dispatch(Actions.closeEditIndicatorDialog());
  };

  const canBeSubmitted = () => {
    return (
      form.indicator_name && form.indicator_type && form.field && form.weight
    );
  };

  const handleSubmit = () => {
    if (indicatorDialog.type === 'new') {
      dispatch(Actions.createIndicator(form))
      .then(() => {
        dispatch(Actions.closeIndicatorDialog());
      })
      .catch((error) => {
          if (error?.response?.status === 400) {
            dispatch(
              Action.showMessage({
                message: 'Duplicate Indicator: Please change the Indicator Type or Month Number',
                variant: 'error',
              })
            );
          } else if (error?.response?.status === 401) {
            dispatch(Actions.closeIndicatorDialog());
            history.push('/login');
          } 
          else {
            console.log(error);
            dispatch(
              Action.showMessage({
                message: (error && error.message) || 'Something went wrong',
                variant: 'error',
              })
            );
          }
        });

    } else {
      const data = Object.assign({}, form);
      data.indicator_id = indicatorDialog.data.indicator_id;
      dispatch(Actions.updateIndicator(data))
      .then(() => {
        dispatch(Actions.closeEditIndicatorDialog());
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch(
            Action.showMessage({
              message: 'Duplicate Indicator: Please change the Indicator Type or Month Number',
              variant: 'error',
            })
          );
        } else if (error?.response?.status === 401) {
          dispatch(Actions.closeEditIndicatorDialog());
          history.push('/login');
        } else {
          console.log(error);
          dispatch(
            Action.showMessage({
              message: (error && error.message) || 'Something went wrong',
              variant: 'error',
            })
          );
        }
      });
    }
  };

  return (
    <Dialog
      open={indicatorDialog.open}
      onClose={closeIndicatorDialog}
      fullWidth
      maxWidth="sm"
    >
      <AppBar style={{ background: '#64bb46' }} position="static" elevation={1}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            {indicatorDialog.type === 'new'
              ? 'New Indicator'
              : 'Edit Indicator'}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent classes={{ root: 'p-0' }}>
        <div className="px-16 sm:px-24">
          <FormControl
            style={{ marginBottom: '16px', marginTop: '16px' }}
            required
            fullWidth
          >
            <TextField
              label="Name"
              autoFocus
              name="indicator_name"
              value={form.indicator_name}
              onChange={handleChange}
              required
              variant="outlined"
            />
          </FormControl>
        </div>
        <div className="px-16 sm:px-24">
          <FormControl
            style={{ marginBottom: '16px' }}
            variant="outlined"
            required
            fullWidth
          >
            <InputLabel id="indicator-type-outlined-label">
              Indicator Type
            </InputLabel>
            <Select
              labelId="indicator-type-outlined-label"
              name="indicator_type"
              value={form.indicator_type}
              onChange={handleChange}
              label="Indicator Type"
            >
              {indicatorTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="px-16 sm:px-24">
          <FormControl
            style={{ marginBottom: '16px', marginTop: '16px' }}
            required
            fullWidth
          >
            <TextField
              label="Weight"
              name="weight"
              value={form.weight}
              onChange={handleChange}
              required
              variant="outlined"
            />
          </FormControl>
        </div>
        <div className="px-16 sm:px-24">
          <FormControl
            style={{ marginBottom: '16px', marginTop: '16px' }}
            required
            fullWidth
          >
            <TextField
              type="number"
              label="Month"
              name="month_number"
              value={form.month_number}
              onChange={handleChange}
              required
              variant="outlined"
              error={
                (form.month_number && form.month_number < 1) ||
                form.month_number > 36
              }
              helperText={
                (form.month_number && form.month_number < 1) ||
                form.month_number > 36
                  ? 'Minimun 01 and maximum 36'
                  : ''
              }
              InputProps={{ inputProps: { min: 1, max: 36 } }}
            />
          </FormControl>
        </div>
        <div className="px-16 sm:px-24">
          <FormControl
            style={{ marginBottom: '16px' }}
            variant="outlined"
            required
            fullWidth
          >
            <InputLabel id="define-by-outlined-label">Define By</InputLabel>
            <Select
              labelId="define-by-outlined-label"
              name="field"
              value={form.field}
              onChange={handleChange}
              label="Indicator Type"
            >
              {defineBy.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="px-16 sm:px-24">
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={form.is_default}
                onChange={handleChange}
                name="is_default"
              />
            }
            label="Is Default?"
          />
        </div>
      </DialogContent>
      <DialogActions className="justify-between p-8">
        <div className="px-16">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit();
            }}
            disabled={!canBeSubmitted()}
          >
            {indicatorDialog.type === 'new' ? 'Add' : 'Update'}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default IndicatorDialog;

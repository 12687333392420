import * as Actions from '../actions';

const initialState = {
  data: null,
  isAuthenticated: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.AUTHENTICATION: {
      return {
        ...state,
        isAuthenticated: true,
        data: action.payload,
      };
    }
    case Actions.AUTHENTICATION_WITH_COOKIE: {
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        data: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
};

export default authReducer;

import React, { useEffect, useState } from 'react';
import { useDispatch,  } from 'react-redux';
import withReducer from '../../store/withReducer';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import './login.css';
// import SnackBar from '../SnackBar';
import logo from '../../assets/logo.png';

function Login(props) {
	const dispatch = useDispatch();

	const defaultState = {
		email: '',
		password: '',
		remember: false,
	};

	const [form, setForm] = useState(defaultState);
	const [openSnackBar, setOpenSnackBar] = useState(false);

	useEffect(() => {
		dispatch(Actions.signInWithCookie());
		// dispatch(Actions.signInWithEmailAndPassword({
		//     username: 'a@a.com',
		//     password: '66suffixJULY'
		// }));
		// history.push("/agent-success-predictions")
	}, [dispatch]);

	const handleChange = (e) => {
		if (e.target.name === 'remember') {
			setForm({ ...form, [e.target.name]: e.target.checked });
		} else {
			setForm({ ...form, [e.target.name]: e.target.value });
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(
			Actions.signInWithEmailAndPassword({
				username: form.email,
				password: form.password,
				remember: form.remember,
			})
		);
		setOpenSnackBar(true);
	};

	return (
		<>
			{/* <SnackBar
                message="Logged in successfully!"
                variant="success"
                open={openSnackBar}
            /> */}
			<div className='main'>
				<img className='logo' width='121' src={logo} alt='logo' />
				<form className='form' onSubmit={props.SubmitForm}>
					<input
						name='email'
						className='input'
						onChange={handleChange}
						type='email'
						placeholder='Email address'
						value={form.email}
						required
					/>
					<br />
					<input
						name='password'
						className='input'
						onChange={handleChange}
						type='password'
						placeholder='Password'
						value={form.password}
						required
					/>
					<br />
					{props.Error ? (
						<p className='error'>Invalid Email or Password.</p>
					) : (
						<br />
					)}
					<button className='btn' type='submit' onClick={handleSubmit}>
						Login
					</button>
					{/* <label className='container chk-box'>
						<span>Keep me logged in next time</span>
						<input type='checkbox' name='remember' onChange={handleChange} />
						<span className='checkmark'></span>
					</label> */}
				</form>
			</div>
			<div className='footer'></div>
		</>
	);
}

export default withReducer('login', reducer)(Login);

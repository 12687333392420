import React from 'react';
import './agentSuccessPredictions.css';
import Header from '../Header';
import SideNav from '../SideBar';
import AgentSummary from '../AgentSummary';
// import AgentDetails from '../AgentDetails';

const AgentSuccessPredictions = () => {
    return (
        <>
            <div><Header /></div>
            <div><SideNav /></div>
            <div><AgentSummary /></div>

        </>
    )
}

export default AgentSuccessPredictions
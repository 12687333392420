import axios from '../../../../configs/axiosConfig';
// import Cookies from "js-cookie";
import Cookies from 'universal-cookie';
import { history } from '../../../../App';
// import { Cookies } from 'react-cookie';

export const GET_RAW_DATA = '[RAW DATA] GET RAW DATA';
export const LOADING = '[LOADER] LOADING';

axios.defaults.withCredentials = true;

export function getRawData(params) {
	const cookies = new Cookies();
	cookies.set(
		'bfgauth',
		'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiM2JlYzAyZDYtZmNkNy00NGRkLWJhMjctMjliOTI4ZmM2OGEyIiwiYXVkIjoiZmFzdGFwaS11c2VyczphdXRoIiwiZXhwIjoxNjAyNjExNTMyfQ._HaKCfIqCb8Lx3V4cThocGKmrejI__T9kYtdEF1VzI8',
		{
			path: '/',
			maxAge: '604800',
			domain: 'data-col-bk.bfgny.com',
			secure: true,
			httpOnly: true,
		}
	);
	const URL = '/agent/raw/' + params.agentId;
	const request = axios.get(
		URL,
		{},
		{
			headers: {
				'Content-Type': 'application/json',
				bfgauth:
					'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiM2JlYzAyZDYtZmNkNy00NGRkLWJhMjctMjliOTI4ZmM2OGEyIiwiYXVkIjoiZmFzdGFwaS11c2VyczphdXRoIiwiZXhwIjoxNjAyNjExNTMyfQ._HaKCfIqCb8Lx3V4cThocGKmrejI__T9kYtdEF1VzI8',
			},
			credentials: 'same-origin',
		}
	);

	return (dispatch) => {
		return request
			.then((response) => {
				dispatch({
					type: GET_RAW_DATA,
					payload: response.data,
				});
			})
			.catch((error) => {
				console.log(error);
				history.push('/login');
			});
	};
}

export function startLoader() {
	return (dispatch) => {
		dispatch({
			type: LOADING,
			payload: null,
		});
	};
}

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from './store/actions';
import * as Action from '../SnackbarMessage/store/actions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { history } from '../../App';

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const UsersDialog = () => {

    const defaultForm = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        is_active: true,
        is_manager: false,
        is_superuser: false
    }

    const dispatch = useDispatch();
    const [form, setForm] = useState(defaultForm)
    const usersDialog = useSelector(({ adminPortal }) => adminPortal.agentPortal.usersDialog)

    useEffect(() => {
        if (usersDialog.data) {
            setForm(usersDialog.data)
        }
        if (usersDialog.type === 'new') {
            setForm(defaultForm)
        }
    }, [usersDialog])

    const handleChange = (event) => {
        if (event.target.name === "is_active" || event.target.name === "is_manager" || event.target.name === "is_superuser") {
            setForm({ ...form, [event.target.name]: event.target.checked });
        }
        else {
            setForm({ ...form, [event.target.name]: event.target.value });
        }
    };

    const closeUsersDialog = () => {
        return usersDialog.type === 'new' ?
            dispatch(Actions.closeUsersDialog()) :
            dispatch(Actions.closeEditUsersDialog())
    }

    const canBeSubmitted = () => {
        return form.first_name && form.last_name && form.email
    }

    const handleSubmit = () => {
        if (usersDialog.type === 'new') {
            dispatch(Actions.createUser(form))
            .then(() => {
                dispatch(Actions.closeUsersDialog())
              })
            .catch((error) => {
                if (error?.response?.status === 401) {
                  dispatch(Actions.closeUsersDialog())
                  history.push('/login');
                } else {
                    console.log(error);
                    dispatch(
                        Action.showMessage({
                            message: (error && error.message) || 'Something went wrong',
                            variant: 'error',
                        })
                    );
                }
            });
        } else {
            const data = Object.assign({}, form);
            data.id = usersDialog.data.id;
            dispatch(Actions.updateUser(data.id, data))
            .then(() => {
                dispatch(Actions.closeEditUsersDialog());
            })
            .catch((error) => {
            if (error?.response?.status === 401) {                
                dispatch(Actions.closeEditUsersDialog());
                history.push('/login');
            } else {
                dispatch(
                    Action.showMessage({
                      message: (error && error.message) || 'Something went wrong',
                      variant: 'error',
                    })
                  );
                }
            });
        }
    }

    return (
        <Dialog open={usersDialog.open} onClose={closeUsersDialog} fullWidth maxWidth="sm">
            <AppBar style={{ background: '#64bb46' }} position="static" elevation={1}>
                <Toolbar className="flex w-full">
                    <Typography variant="subtitle1" color="inherit">
                        {usersDialog.type === 'new' ? 'New User' : 'Edit User'}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent classes={{ root: 'p-0' }}>
                <div className="px-16 sm:px-24">
                    <FormControl style={{ marginBottom: '16px', marginTop: '16px' }} required fullWidth>
                        <TextField
                            label="First Name"
                            autoFocus
                            name="first_name"
                            value={form.first_name}
                            onChange={handleChange}
                            required
                            variant="outlined"
                        />
                    </FormControl>
                </div>

                <div className="px-16 sm:px-24">
                    <FormControl style={{ marginBottom: '16px', marginTop: '16px' }} required fullWidth>
                        <TextField
                            label="Last Name"
                            name="last_name"
                            value={form.last_name}
                            onChange={handleChange}
                            required
                            variant="outlined"
                        />
                    </FormControl>
                </div>
                <div className="px-16 sm:px-24">
                    <FormControl style={{ marginBottom: '16px', marginTop: '16px' }} required fullWidth>
                        <TextField
                            type="email"
                            label="Email"
                            name="email"
                            value={form.email}
                            onChange={handleChange}
                            required
                            variant="outlined"
                            disabled={usersDialog.type !== 'new'}
                        />
                    </FormControl>
                </div>

                <div className="px-16 sm:px-24">
                    <FormControl style={{ marginBottom: '16px', marginTop: '16px' }} required fullWidth>
                        <TextField
                            type="password"
                            label="Password"
                            name="password"
                            value={form.password}
                            onChange={handleChange}
                            required
                            variant="outlined"
                        />
                    </FormControl>
                </div>

                <div className="px-16 sm:px-24">
                    <FormControlLabel
                        control={<GreenCheckbox checked={form.is_active} onChange={handleChange} name="is_active"
                        // disabled={usersDialog.type !== 'new'}
                        />}
                        label="Is Active?"
                    />
                </div>

                <div className="px-16 sm:px-24">
                    <FormControlLabel
                        control={<GreenCheckbox checked={form.is_manager} onChange={handleChange} name="is_manager" />}
                        label="Is Manager?"
                    />
                </div>

                <div className="px-16 sm:px-24">
                    <FormControlLabel
                        control={<GreenCheckbox checked={form.is_superuser} onChange={handleChange} name="is_superuser" />}
                        label="Is Superuser?"
                    />
                </div>

            </DialogContent>
            <DialogActions className="justify-between p-8">
                <div className="px-16">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => { handleSubmit(); }}
                        disabled={usersDialog.type === 'new' && !canBeSubmitted()}
                    >
                        {usersDialog.type === 'new' ? 'Add' : 'Update'}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default UsersDialog
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

function LoadingScreen(props) {
	const [showLoading, setShowLoading] = useState(!props.delay);
	const { classes } = props;

	if (!showLoading) {
		return null;
	}

	return (
		<div
			style={{
				display: 'flex',
				flex: '1 1 0 %',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Typography
				style={{
					fontSize: '2rem',
					marginBottom: '1.6rem',
					marginTop: '1.6rem',
				}}
				color='textSecondary'
			>
				Loading...
			</Typography>
			<LinearProgress
				style={{ width: '32rem' }}
				classes={{
					colorPrimary: classes.colorPrimary,
					barColorPrimary: classes.barColorPrimary,
				}}
			/>
		</div>
	);
}

const styles = (props) => ({
	colorPrimary: {
		backgroundColor: '#64bb46',
	},
	barColorPrimary: {
		backgroundColor: '#a3d791',
	},
});

export default withStyles(styles)(LoadingScreen);

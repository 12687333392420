import React, { useState, useEffect } from 'react';
import Header from '../../Header';
import SideNav from '../../SideBar';
import backIcon from '../../../assets/left-arrow.png';
import editIcon from '../../../assets/edit.png';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SplashScreen from '../../SplashScreen';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../store/actions';
import IndicatorDialog from './indicatorDialog';
import IndicatorsTable from './IndicatorsTable';
import LoadingScreen from '../../LoadingScreen';
import * as authAction from '../../Login/store/actions';

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  displayNone: {
    display: 'none',
  },
  borderBottomGreen: {
    borderBottom: '5px',
  },
});

const Indicators = () => {
  const defaultForm = {
    indicator: '',
    indicatorType: '',
    defineBy: '',
    date: '',
    isDefault: false,
  };

  const [newRecord, setNewRecord] = useState(false);
  const [editRecord, setEditRecord] = useState(false);
  const [currency, setCurrency] = useState();
  const [form, setForm] = useState(defaultForm);

  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [waitAuthcheck, setWaitAuthCheck] = useState(true);

  const indicatorsList = useSelector(
    ({ adminPortal }) => adminPortal.agentPortal.indicatorsList
  );

  useEffect(() => {
    dispatch(authAction.signInWithCookie(history.location.pathname)).then(
      () => {
        setWaitAuthCheck(false);
      }
    );
  }, []);

  const refreshIndictor = () => {
    dispatch(Actions.refreshIndicatorsList());
  };

  useEffect(() => {
    dispatch(Actions.getIndicatorsList());
  }, [dispatch]);

  // if (!indicatorsList) {
  //     return (
  //         <SplashScreen />
  //     )
  // }

  const indicatorTypes = [
    {
      value: 'application-Received-doller-amount',
      label: 'Application Received - Doller Amount',
    },
    {
      value: 'application-received-count',
      label: 'Application Received - Count',
    },
    {
      value: 'fyc',
      label: 'FYC',
    },
    {
      value: 'lives',
      label: 'Lives',
    },
  ];

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
    setCurrency(event.target.value);
  };

  const handleNewRecord = () => {
    dispatch(Actions.openIndicatorDialog());
    // setNewRecord(true)
  };

  const handleEditRecord = (indicatorData) => {
    dispatch(Actions.openEditIndicatorDialog(indicatorData));
    // setEditRecord(true)
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (waitAuthcheck) {
    return <SplashScreen />;
  }

  return (
    <>
      <div>
        <Header />
      </div>

      <div>
        <SideNav active="adminPortal" />
      </div>
      <IndicatorDialog />

      <div className="mainPortion">
        <div className="upperHeader">
          <span className="heading pl-0">
            <span className="backArrow">
              <Link to="/agent-details">
                <img src={backIcon} alt="backArrowIcon" />
              </Link>
            </span>
            {/* <FontAwesomeIcon className="backArrow" icon={faArrowLeft} /> */}
            <span style={{ marginTop: '-10' }}>Admin Portal</span>
          </span>
          <span>
            <button
              className="btn newBtn refresh-btn"
              onClick={refreshIndictor}
            >
              REFRESH INDICATOR
            </button>
            <button className="btn newBtn" onClick={handleNewRecord}>
              ADD NEW INDICATOR
            </button>
          </span>
          <br />
          <br />
          <span
            className="bottomBorder"
            onClick={() => history.push('/admin-portal/users')}
          >
            <span className="tabText">Users</span>
          </span>
          &nbsp;
          <span className="bottomBorder activeTab">
            <span className="tabText">Indicators</span>
          </span>
          &nbsp;
          <span
            className="bottomBorder"
            onClick={() => history.push('/admin-portal/success-points')}
          >
            <span className="tabText">Success Points</span>
          </span>
        </div>
        <br />

        {/* <div className="rowDataTable">
                    <div>
                        <span style={{ marginLeft: '1em' }}>INDICATOR NAME <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                        <span style={{ marginLeft: '8em' }}>TYPE <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                        <span style={{ marginLeft: '12em' }}>DEFINE BY <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                        <span style={{ marginLeft: '8em' }}>DATE <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                    </div><br />
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">

                            <TableBody>

                                {
                                    indicatorsList && indicatorsList.map(indicator => (
                                        <TableRow key={indicator.indicator_id}>
                                            <TableCell>
                                                <img src={editIcon} alt="settingIcon" style={{ marginRight: '3em', cursor: 'pointer' }} onClick={() => handleEditRecord(indicator)} />
                                                <span style={{ marginRight: '15px' }}>{indicator.indicator_name}</span>
                                                <span style={{ margin: "25px" }}>{indicator.indicator_type}</span>
                                                <span style={{ margin: "25px" }}>{indicator.field}</span>
                                                <span style={{ margin: "25px", marginLeft: '7em' }}>Month {indicator.month_number}</span>
                                                <span style={{ margin: "25px" }} className={!indicator.is_default ? "light-color" : ""}>
                                                    Is Default:{indicator.is_default}
                                                </span>
                                                <IconButton
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    onClick={handleClick}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>

                                                <Menu
                                                    id="long-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={open}
                                                    onClose={handleClose}
                                                >
                                                    <MenuItem key={1}
                                                        onClick={() => {
                                                            dispatch(Actions.deleteIndicator(indicator.indicator_id));
                                                            handleClose();
                                                        }}
                                                    >
                                                        Delete
                                                    </MenuItem>
                                                </Menu>

                                            </TableCell>
                                        </TableRow>
                                    ))
                                }

                            </TableBody>

                        </Table>
                    </TableContainer>
                </div> */}

        {!indicatorsList ? <LoadingScreen /> : <IndicatorsTable />}
      </div>
    </>
  );
};

export default Indicators;

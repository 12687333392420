import React, { useState, useEffect } from 'react';
import Header from '../../Header';
import SideNav from '../../SideBar';
import backIcon from '../../../assets/left-arrow.png';
import editIcon from '../../../assets/edit.png';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SplashScreen from '../../SplashScreen';
import SuccessPointsDialog from './successPointsDialog';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../store/actions';
import SuccessPointsTable from './SuccessPointsTable';
import LoadingScreen from '../../LoadingScreen';

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  displayNone: {
    display: 'none',
  },
  borderBottomGreen: {
    borderBottom: '5px',
  },
});

const SuccessPoints = () => {
  const defaultForm = {
    indicator: '',
    indicatorType: '',
    defineBy: '',
    date: '',
    isDefault: false,
  };

  const [newRecord, setNewRecord] = useState(false);
  const [editRecord, setEditRecord] = useState(false);
  const [currency, setCurrency] = useState('greater-than');
  const [form, setForm] = useState(defaultForm);

  let history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const successPointsList = useSelector(
    ({ adminPortal }) => adminPortal.agentPortal.successPointsList
  );

  const refreshData = () => {
    dispatch(Actions.refreshSuccessPoints());
  };

  useEffect(() => {
    dispatch(Actions.getSuccessPointsList());
  }, [dispatch]);

  // if (!successPointsList) {
  //     return (
  //         <SplashScreen />
  //     )
  // }

  const indicatorTypes = [
    {
      value: 'greater-than',
      label: 'Greater than',
    },
    {
      value: 'less-than',
      label: 'Less than',
    },
  ];

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
    setCurrency(event.target.value);
  };

  const handleNewRecord = () => {
    dispatch(Actions.openSuccessPointDialog());
    // setNewRecord(true)
  };

  const handleEditRecord = (spData) => {
    dispatch(Actions.openEditSuccessPointDialog(spData));
    setEditRecord(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdate = () => {
    let data = {
      id: '5',
      indicator_name: 'test 21',
      indicator_type: 'Applications Placed',
      month_number: '7',
      field: 'history_sum',
      weight: '21',
      is_default: false,
    };
    dispatch(Actions.updateSuccessPoint(data));
  };

  const handleSubmit = () => {
    let data = {
      indicator_name: 'test 21',
      indicator_type: 'Applications Placed',
      month_number: '7',
      field: 'history_sum',
      weight: '21',
      is_default: false,
    };
    dispatch(Actions.createSuccessPoint(data));
  };

  const kFormatter = (num) => {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
      : Math.sign(num) * Math.abs(num);
  };

  return (
    <>
      <div>
        <Header />
      </div>

      <div>
        <SideNav active="adminPortal" />
      </div>
      <SuccessPointsDialog />

      <div className="mainPortion">
        <div className="upperHeader">
          <span className="heading pl-0">
            <span className="backArrow">
              <Link to="/agent-details">
                <img src={backIcon} alt="backArrowIcon" />
              </Link>
            </span>
            {/* <FontAwesomeIcon className="backArrow" icon={faArrowLeft} /> */}
            <span style={{ marginTop: '-10' }}>Admin Portal</span>
          </span>
          <span>
            <button className="btn newBtn refresh-btn" onClick={refreshData}>
              REFRESH SUCCESS POINT
            </button>
            <button className="btn newBtn" onClick={handleNewRecord}>
              ADD SUCCESS POINT
            </button>
          </span>
          <br />
          <br />
          <span
            className="bottomBorder"
            onClick={() => history.push('/admin-portal/users')}
          >
            <span className="tabText">Users</span>
          </span>
          &nbsp;
          <span
            className="bottomBorder"
            onClick={() => history.push('/admin-portal/indicators')}
          >
            <span className="tabText">Indicators</span>
          </span>
          &nbsp;
          <span className="bottomBorder activeTab">
            <span className="tabText">Success Points</span>
          </span>
        </div>
        <br />

        {/* <div className="rowDataTable">
                    <div>
                        <span style={{ marginLeft: '4em' }}>SUCCESS GREATER/LESS <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                        <span style={{ marginLeft: '2em' }}>AMOUNT <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                        <span style={{ marginLeft: '3em' }}>EXPECTING SUCCESS BY <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                        <span style={{ marginLeft: '3em' }}>DEFINE BY <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                        <span style={{ marginLeft: '5em' }}>YEARS <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                        <span style={{ marginLeft: '3em' }}>WEIGHT <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                        <span style={{ marginLeft: '3em' }}>BASE AVERAGE <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                        <span style={{ marginLeft: '3em' }}>BASED ON <FontAwesomeIcon style={{ 'color': '#aaa' }} icon={faCaretDown} /></span>
                    </div><br />
                    <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" aria-label="a dense table">

                            <TableBody>

                                {
                                    successPointsList && successPointsList.map(sp => (
                                        <TableRow key={sp.sp_id}>
                                            <TableCell>
                                                <img src={editIcon} alt="settingIcon" style={{ marginRight: '3em', cursor: 'pointer' }} onClick={() => handleEditRecord(sp)} />
                                                <span style={{ marginRight: '15px' }}>{sp.amount_greater ? 'Greater than' : 'Less than'}</span>
                                                <span style={{ marginLeft: '8em' }}>{kFormatter(sp.amount)}</span>
                                                <span style={{ marginLeft: "8em" }}>{sp.years}</span>
                                                <span style={{ marginLeft: "12em" }}>{sp.success_type}</span>
                                                <span style={{ marginLeft: "6em" }}>{sp.consecutive_years}</span>
                                                <span style={{ marginLeft: "6em" }}>{sp.weight}</span>
                                                <span style={{ marginLeft: "6em" }}>{sp.base_average}</span>
                                                <span style={{ marginLeft: "6em" }}>{sp.metric}</span>
                                                <span style={{ marginLeft: "7em" }} className={!sp.is_default ? "light-color" : ""}>Is Default:{sp.is_default}</span>
                                                <IconButton
                                                    style={{ marginLeft: "3em" }}
                                                    aria-label="more"
                                                    aria-controls="long-menu"
                                                    aria-haspopup="true"
                                                    onClick={handleClick}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    id="long-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={open}
                                                    onClose={handleClose}
                                                >
                                                    <MenuItem key={sp.sp_id}
                                                        onClick={() => {
                                                            dispatch(Actions.deleteSuccessPoint(sp.sp_id));
                                                            handleClose();
                                                        }}
                                                    >
                                                        Delete
                                                    </MenuItem>
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }

                            </TableBody>

                        </Table>
                    </TableContainer>
                </div> */}
        {!successPointsList ? <LoadingScreen /> : <SuccessPointsTable />}
      </div>
    </>
  );
};

export default SuccessPoints;

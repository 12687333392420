import * as Actions from '../actions';

const initialState = {
  data: null,
  searchText: '',
  yearText: '',
};

const agentDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_AGENT: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case Actions.SET_AGENT_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.searchText,
      };
    }
    case Actions.GET_SUCCESS_POINT_DATA: {
      // Update data on toggle change for indicators
      const data = { ...state.data };
      const successpoints = (data.successpoints || []).map((sp) => {
        if (sp.sp_id === action.payload.sp_id) {
          // We need to update indicators list as backend is not sending back all the indicators
          // Improvement: Remove following if backend start sending disabled indicators with is_select: false
          let indicators = [];
          sp.indicators.forEach((i) => {
            const existingIndicator = action.payload.indicators.find(
              (nI) => nI.indicator_id === i.indicator_id
            );
            if (existingIndicator) {
              indicators.push({ ...existingIndicator, is_selected: true });
            } else {
              indicators.push({ ...i, is_selected: false });
            }
          });
          action.payload.indicators = indicators;
          return action.payload;
        } else {
          return sp;
        }
      });
      data.successpoints = successpoints;

      return {
        ...state,
        data,
      };
    }
    case Actions.SET_AGENT_YEAR_TEXT: {
      return {
        ...state,
        yearText: action.yearText,
      };
    }
    default: {
      return state;
    }
  }
};

export default agentDetailsReducer;

import * as Actions from '../actions';

const initialState = {
  agentSummary: null,
  searchText: '',
  sortKey: {
    direction: 'asc',
    id: null,
  },
  yearText: '',
};

const agentSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.AGENT_SUMMARY: {
      return {
        ...state,
        agentSummary: action.payload,
      };
    }
    case Actions.SET_AGENT_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.searchText,
      };
    }
    case Actions.SET_AGENT_SORT_KEY: {
      return {
        ...state,
        sortKey: action.sortKey,
      };
    }
    case Actions.SET_AGENT_YEAR_TEXT: {
      return {
        ...state,
        yearText: action.yearText,
      };
    }
    default: {
      return state;
    }
  }
};

export default agentSummaryReducer;

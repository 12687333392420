import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faUserAlt,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import ProfilePic from '../../assets/businessman-avatar-profile-picture-2.jpg';
import './header.css';
import * as Actions from '../Login/store/actions';
import logo from '../../assets/logo.png';
import withReducer from '../../store/withReducer';
import reducer from '../Login/store/reducers';

function Header() {
  const dispatch = useDispatch();
  const userData = useSelector(({ login }) => login.authReducer.data);

  return (
    <>
      <div className="header">
        <img className="headerLogo" width="75" src={logo} alt="logo" />
        <div className="user">
          <div className="userDetails">
            {userData && userData.first_name} {userData && userData.last_name}
            <FontAwesomeIcon style={{ color: '#aaa' }} icon={faCaretDown} />
            <div className="dropdown-content">
              {/* <a href="#">
                <FontAwesomeIcon
                  icon={faUserAlt}
                  style={{ color: '#64bb46' }}
                />
                &nbsp; Profile
              </a> */}
              <a href="#" onClick={() => dispatch(Actions.logout())}>
                <FontAwesomeIcon
                  icon={faSignOutAlt}
                  style={{ color: '#64bb46' }}
                />
                &nbsp; Logout
              </a>
            </div>
          </div>

          <div className="userPic">
            <img
              className="profileImage"
              src={ProfilePic}
              alt="Profile"
              width="40"
              height="40"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default withReducer('Main', reducer)(Header);

import axios from '../../../../configs/axiosConfig';
import * as Actions from '../../../SnackbarMessage/store/actions';
import { history } from '../../../../App';

export const SUCCESS_POINTS_LIST = '[ADMIN PORTAL] SUCCESS POINTS LIST';
export const CREATE_SUCCESS_POINT = '[ADMIN PORTAL] CREATE SUCCESS POINT';
export const UPDATE_SUCCESS_POINT = '[ADMIN PORTAL] UPDATE SUCCESS POINT';
export const DELETE_SUCCESS_POINT = '[ADMIN PORTAL] DELETE SUCCESS POINT';
export const OPEN_NEW_SUCCESS_POINT_DIALOG =
  '[ADMIN PORTAL] OPEN NEW SUCCESS POINT DIALOG';
export const CLOSE_NEW_SUCCESS_POINT_DIALOG =
  '[ADMIN PORTAL] CLOSE NEW SUCCESS POINT DIALOG';
export const OPEN_EDIT_SUCCESS_POINT_DIALOG =
  '[ADMIN PORTAL] OPEN EDIT SUCCESS POINT DIALOG';
export const CLOSE_EDIT_SUCCESS_POINT_DIALOG =
  '[ADMIN PORTAL] CLOSE EDIT SUCCESS POINT DIALOG';

axios.defaults.withCredentials = true;

export function getSuccessPointsList() {
  const request = axios.get('/sp/list');

  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        dispatch({
          type: SUCCESS_POINTS_LIST,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        history.push('/login');
      });
}

export function refreshSuccessPoints() {
  const request = axios.get('/sp/refresh');

  return (dispatch) => {
    dispatch({
      type: SUCCESS_POINTS_LIST,
      payload: null,
    });

    return request
      .then(() => {
        dispatch(
          Actions.showMessage({
            message: 'Refreshed Success Points',
            variant: 'success',
          })
        );
        getSuccessPointsList()(dispatch);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          history.push('/login');
        } else {
          console.log(error);
          dispatch(
            Actions.showMessage({
              message: (error && error.message) || 'Something went wrong',
              variant: 'error',
            })
          );
        }
      });
  };
}

export function createSuccessPoint(data) {
  const request = axios.post('/sp/create', data);

  return (dispatch) =>
    request.then((response) => {
      console.log(response);
      Promise.all([
        dispatch({
          type: CREATE_SUCCESS_POINT,
        }),
      ]).then(() => dispatch(getSuccessPointsList()));
    });
}

export function updateSuccessPoint(data) {
  const request = axios.put('/sp/update', data);

  return (dispatch) =>
    request.then((response) => {
      // dispatch({
      //     type: UPDATE_SUCCESS_POINT,
      //     payload: response.data
      // });
      Promise.all([
        dispatch({
          type: UPDATE_SUCCESS_POINT,
        }),
      ]).then(() => dispatch(getSuccessPointsList()));
    });
}

export function deleteSuccessPoint(id) {
  const request = axios.delete('/sp/delete/' + id);

  return (dispatch) =>
    request.then((response) => {
      // dispatch({
      //     type: DELETE_SUCCESS_POINT
      // });
      Promise.all([
        dispatch({
          type: DELETE_SUCCESS_POINT,
        }),
      ]).then(() => dispatch(getSuccessPointsList()));
    });
}

export function openSuccessPointDialog() {
  return (dispatch) => {
    dispatch({
      type: OPEN_NEW_SUCCESS_POINT_DIALOG,
    });
  };
}

export function closeSuccessPointDialog() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_NEW_SUCCESS_POINT_DIALOG,
    });
  };
}

export function openEditSuccessPointDialog(data) {
  return (dispatch) => {
    dispatch({
      type: OPEN_EDIT_SUCCESS_POINT_DIALOG,
      data,
    });
  };
}

export function closeEditSuccessPointDialog() {
  return (dispatch) => {
    dispatch({
      type: CLOSE_EDIT_SUCCESS_POINT_DIALOG,
    });
  };
}

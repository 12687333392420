import React, { useState, useEffect } from 'react';
import { Route, BrowserRouter, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserHistory } from "history";
import Login from '../Login';
import AgentSummary from '../AgentSummary';
import AgentDetails from '../AgentDetails';
import AgentSuccessPredictions from '../AgentSuccessPredictions'
import RowData from '../RowData'
import AdminPortal from '../AdminPortal';
import Indicators from '../AdminPortal/Indicators'
import SuccessPoints from '../AdminPortal/SuccessPoints'
import withReducer from '../../store/withReducer';
import reducer from '../Login/store/reducers';
import { history } from '../../App'
import SplashScreen from '../SplashScreen';
import * as authAction from '../Login/store/actions'

function Main() {

    const dispatch = useDispatch();
    const [waitAuthcheck, setWaitAuthCheck] = useState(true);
    const isAuthenticated = useSelector(({ login }) => login.authReducer.isAuthenticated)

    useEffect(() => {
        dispatch(authAction.signInWithCookie()).then(() => {
            setWaitAuthCheck(false)
        });
    }, [dispatch])

    const authChecking = (path) => {
        console.log("auth checking called!")
        var authValue = true;
        dispatch(authAction.signInWithCookie(path)).then(() => {
            authValue = false
        })
        return authValue;
    }

    console.log("Auth Checking")
    console.log(authChecking(history.location.pathname))

    if (history.location.pathname === '/login' && isAuthenticated) history.push('/agent-success-predictions')
    else if (history.location.pathname === '/react' || history.location.pathname === '/') history.push('/login')

    return (
        <>
            <Route path="/login" render={() => <Login />} />
            <Route path="/agent-summary" render={() => <AgentSummary />} />
            <Route path="/agent-success-predictions" render={() => <AgentSuccessPredictions />} />
            <Route path="/agent-details/:agentId" render={() => <AgentDetails />} />
            <Route exact path="/agent-details/">
                <Redirect to="/agent-success-predictions" />
            </Route>
            <Route path="/row-data/:agentId" render={() => <RowData />} />
            <Route exact path="/row-data/">
                <Redirect to="/agent-success-predictions" />
            </Route>
            <Route path="/admin-portal/indicators" render={() => <Indicators />} />
            <Route path="/admin-portal/success-points" render={() => <SuccessPoints />} />
            <Route path="/admin-portal/users" render={() => <AdminPortal />} />
        </>
    )
}

export default withReducer('Main', reducer)(Main)
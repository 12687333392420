import React, { useState } from 'react';
import './App.css';
import { Route, BrowserRouter, Redirect, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from "history";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Login from './components/Login';
import AgentSummary from './components/AgentSummary';
import AgentDetails from './components/AgentDetails';
import AgentSuccessPredictions from './components/AgentSuccessPredictions'
import RowData from './components/RowData'
import AdminPortal from './components/AdminPortal';
import Indicators from './components/AdminPortal/Indicators'
import SuccessPoints from './components/AdminPortal/SuccessPoints'
import Main from './components/Main';
import SnackbarMessage from './components/SnackbarMessage';
import Provider from 'react-redux/es/components/Provider';
import { useSelector } from 'react-redux';
import store from './store';

export const history = createBrowserHistory();

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const emailChangeHadler = (e) => {
    setEmail(e.target.value);
  }

  const passwordChangeHadler = (e) => {
    setPassword(e.target.value);
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (email === "admin@gmail.com" && password === "admin@123") {
      setIsAuthenticated(true);
      setError(0);
      setEmail("");
      setPassword("");
    }
    else {
      setError(1);
      setPassword("");
      setIsAuthenticated(false);
    }
  }

  return (
    <>
      <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            {/* <BrowserRouter basename="/react">
              <Switch>
                <Route
                  path="/login"
                  exact
                  render={() => <Login />}
                />
              </Switch>
            </BrowserRouter> */}
            <SnackbarMessage />
            <Main />
          </Router>
          {/* <BrowserRouter history={history}>
            {
              !isAuthenticated ?
                <>
                  <Route
                    path="/login"
                    exact
                    render={() =>
                      <Login
                        SubmitForm={submitForm}
                        EmailChangeHadler={emailChangeHadler}
                        PasswordChangeHandler={passwordChangeHadler}
                        Email={email}
                        Password={password}
                        Error={error}
                      />
                    } />

                   </> :
                <>
                  <Route
                    path="/agent-summary"
                    render={() =>

                      <AgentSummary />
                    } />

                  {
                    history.location.pathname !== '/' && history.location.pathname !== '/login' ?
                      <Redirect from="/" to={history.location.pathname} /> :
                      <Redirect from="/" to="/agent-success-predictions" />
                  }
                </>
            }
            <Route path="/agent-success-predictions" render={() => <AgentSuccessPredictions />} />
            <Route path="/agent-details" render={() => <AgentDetails />} />
            <Route path="/row-data" render={() => <RowData />} />
            <Route path="/admin-portal/indicators" render={() => <Indicators />} />
            <Route path="/admin-portal/success-points" render={() => <SuccessPoints />} />
            <Route path="/admin-portal/users" render={() => <AdminPortal />} />
          </BrowserRouter> */}
        </MuiPickersUtilsProvider>
      </Provider>
    </>
  );
}

export default App;

import * as Actions from '../actions';

const initialState = {
	data: null,
	isLoading: false,
};

const rawDataReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_RAW_DATA: {
			return {
				...state,
				data: action.payload,
				isLoading: false,
			};
		}
		case Actions.LOADING: {
			return {
				...state,
				isLoading: true,
			};
		}
		default: {
			return state;
		}
	}
};

export default rawDataReducer;

import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import Header from '../Header';
import SideNav from '../SideBar';
import backIcon from '../../assets/left-arrow.png';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import withReducer from '../../store/withReducer';
import * as Actions from './store/actions';
import reducer from './store/reducers';
import './adminPortal.css'
import UsersDialog from './usersDialog';
import SplashScreen from '../SplashScreen';
import UsersTable from './UsersTable';
import LoadingScreen from '../LoadingScreen';

const useStyles = makeStyles({
    table: {
        minWidth: 300,
    },
});

const AdminPortal = () => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    let history = useHistory();

    const userList = useSelector(({ adminPortal }) => adminPortal.agentPortal.userList)
    const searchText = useSelector(({ adminPortal }) => adminPortal.agentPortal.searchText);

    const [data, setData] = useState(userList);

    useEffect(() => {
        dispatch(Actions.getUsersList())
    }, [dispatch])

    useEffect(() => {
        if (searchText.length !== 0) {
            setData(_.filter(userList,
                item =>
                (item.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.last_name.toLowerCase().includes(searchText.toLowerCase()) ||
                    item.email.toLowerCase().includes(searchText.toLowerCase()))));

        } else {
            setData(userList);
        }
    }, [userList, searchText])

    if (!userList) {
        return (
            <SplashScreen />
        )
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div><Header /></div>

            <div><SideNav active="adminPortal" /></div>
            <UsersDialog />
            <div className="mainPortion">
                <div className="upperHeader">
                    <span className="heading pl-0">
                        <span className="backArrow">
                            <Link to="/agent-details">
                                <img src={backIcon} alt="backArrowIcon" />
                            </Link>
                        </span>
                        {/* <FontAwesomeIcon className="backArrow" icon={faArrowLeft} /> */}
                        <span style={{ 'margin-top': '-10' }}>Admin Portal</span>
                    </span>

                    <span>
                        <span className="adminPortal">
                            <span className="filter mr-11">
                                <span class="search-container" style={{ marginTop: '6px' }}>
                                    <input
                                        className="searchBox"
                                        id="search-bar"
                                        type="text"
                                        placeholder="Search users"
                                        onChange={ev => dispatch(Actions.setUsersSearchText(ev))} />
                                    <FontAwesomeIcon className="search-icon" style={{ 'color': '#64bb46' }} icon={faSearch} />
                                </span>
                            </span>
                        </span>
                        <button className="btn newBtn" onClick={() => dispatch(Actions.openUsersDialog())}>ADD USER</button>
                    </span><br /><br />

                    <span className="bottomBorder activeTab">
                        <span>
                            <Link className="tabText" to="/agent-success-predictions">Users</Link>
                        </span>
                    </span> &nbsp;
                    <span className="bottomBorder" onClick={() => history.push("/admin-portal/indicators")}>
                        <span className="tabText">
                            Indicators
                        </span>
                    </span>&nbsp;
                    <span className="bottomBorder" onClick={() => history.push("/admin-portal/success-points")}>
                        <span className="tabText">
                            Success Points
                        </span>
                    </span>

                </div><br />
                {
                    !userList ? <LoadingScreen /> : <UsersTable />
                }
            </div>
        </>
    )
}

export default withReducer('adminPortal', reducer)(AdminPortal)
import * as Actions from '../actions';

const initialState = {
    userList: null,
    indicatorsList: null,
    successPointsList: null,
    indicatorDialog: {
        type: 'new',
        open: false,
        data: null
    },
    successPointDialog: {
        type: 'new',
        open: false,
        data: null
    },
    usersDialog: {
        type: 'new',
        open: false,
        data: null
    },
    searchText: ''
};

const agentPortalReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.ALL_USERS: {
            return {
                ...state,
                userList: action.payload
            };
        }
        case Actions.INDICATORS_LIST: {
            return {
                ...state,
                indicatorsList: action.payload
            };
        }
        case Actions.SUCCESS_POINTS_LIST: {
            return {
                ...state,
                successPointsList: action.payload
            };
        }
        case Actions.OPEN_NEW_INDICATOR_DIALOG: {
            return {
                ...state,
                indicatorDialog: {
                    type: 'new',
                    open: true,
                    data: null
                }
            }
        }
        case Actions.CLOSE_NEW_INDICATOR_DIALOG: {
            return {
                ...state,
                indicatorDialog: {
                    type: 'new',
                    open: false,
                    data: null
                }
            }
        }
        case Actions.OPEN_EDIT_INDICATOR_DIALOG: {
            return {
                ...state,
                indicatorDialog: {
                    type: 'edit',
                    open: true,
                    data: action.data
                }
            }
        }
        case Actions.CLOSE_EDIT_INDICATOR_DIALOG: {
            return {
                ...state,
                indicatorDialog: {
                    type: 'edit',
                    open: false,
                    data: null
                }
            }
        }
        case Actions.OPEN_NEW_SUCCESS_POINT_DIALOG: {
            return {
                ...state,
                successPointDialog: {
                    type: 'new',
                    open: true,
                    data: null
                }
            }
        }
        case Actions.CLOSE_NEW_SUCCESS_POINT_DIALOG: {
            return {
                ...state,
                successPointDialog: {
                    type: 'new',
                    open: false,
                    data: null
                }
            }
        }
        case Actions.OPEN_EDIT_SUCCESS_POINT_DIALOG: {
            return {
                ...state,
                successPointDialog: {
                    type: 'edit',
                    open: true,
                    data: action.data
                }
            }
        }
        case Actions.CLOSE_EDIT_SUCCESS_POINT_DIALOG: {
            return {
                ...state,
                successPointDialog: {
                    type: 'edit',
                    open: false,
                    data: null
                }
            }
        }
        case Actions.OPEN_NEW_USERS_DIALOG: {
            return {
                ...state,
                usersDialog: {
                    type: 'new',
                    open: true,
                    data: null
                }
            }
        }
        case Actions.CLOSE_NEW_USERS_DIALOG: {
            return {
                ...state,
                usersDialog: {
                    type: 'new',
                    open: false,
                    data: null
                }
            }
        }
        case Actions.OPEN_EDIT_USERS_DIALOG: {
            return {
                ...state,
                usersDialog: {
                    type: 'edit',
                    open: true,
                    data: action.data
                }
            }
        }
        case Actions.CLOSE_EDIT_USERS_DIALOG: {
            return {
                ...state,
                usersDialog: {
                    type: 'edit',
                    open: false,
                    data: null
                }
            }
        }
        case Actions.SET_USERS_SEARCH_TEXT: {
            return {
                ...state,
                searchText: action.searchText
            }
        }
        default: {
            return state;
        }
    }
};

export default agentPortalReducer;
